'use strict';

import $ from 'jquery';
import 'superfish';
import 'supersubs';

export default class Header {
	constructor() {
    var list = $('#superfish-1');

    list.supersubs({
      "minWidth": "12",
      "maxWidth": "27",
      "extraWidth": 1
    });

    // Apply Superfish to the list.
    list.superfish({
      "delay": "0",
      "animation": {
        "opacity": "show"
      },
      "speed": 0,
      "autoArrows": false,
      "dropShadows": false,
      "disableHI": false
    });

    $('.search-toggle').on('click', e => {
      if ($('.search-toggle').hasClass('expanded')) {
        $('#search-collapse').slideUp();
        $('#mobile-search-collapse').slideUp();
        $('.search-toggle').removeClass('expanded');
      } else {
        $('#search-collapse').slideDown();
        $('#mobile-search-collapse').slideDown();
        $('.search-toggle').addClass('expanded');
      }
    });

    $(document).on('click', e => {
      let $eTarget = $(e.target);

      if (!($eTarget.hasClass('search-toggle') || $eTarget.parents('.search-toggle').length || $eTarget.hasClass('search-container') || $eTarget.parents('.search-container').length || $eTarget.hasClass('mobile-search-container') || $eTarget.parents('.mobile-search-container').length)) {
        $('#search-collapse').slideUp();
        $('#mobile-search-collapse').slideUp();
        $('.search-toggle').removeClass('expanded');
      }

      if (!($eTarget.hasClass('navbar-collapse') || $eTarget.parents('.navbar-collapse').length)) {
        $('.navbar-collapse').collapse('hide');
      }
    })
	}
}
