'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class HomeCarousel {
	constructor() {
    let id;
		$('.jcarousel-link').on('click', function (e) {
      e.preventDefault();

      id = $(this).data('id');
      $('.slick-carousel-home').slick('slickGoTo', id);
    });

    $($('li.home-banner-links').get(0)).addClass('active');
    $($('div.home-banner-links a').get(0)).addClass('active');

    if ($('.jcarousel-link').length > 0) {
      $('.jcarousel-control-prev').on('click', function (e) {
        e.preventDefault();
        id--;
        if (id < 0) id = 3;
        $($('div.home-banner-links a').get(id)).trigger('click');
        $($('li.home-banner-links').get(id)).trigger('click');
      });
      $('.jcarousel-control-next').on('click', function (e) {
        id++;
        if (id > 3) id = 0;
        $($('li.home-banner-links a').get(id)).trigger('click');
        $($('div.home-banner-links').get(id)).trigger('click');
      });
    }

    $('.slick-carousel-home').slick({
      autoplay: true,
      autoplaySpeed: 6000
    });

    $('.slick-carousel-home').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      $('div.home-banner-links a').removeClass('active');
      $('li.home-banner-links').removeClass('active');
      $($('div.home-banner-links a').get(nextSlide)).addClass('active');
      $($('li.home-banner-links').get(nextSlide)).addClass('active');
    });
	}
}
