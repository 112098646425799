'use strict';

import $ from 'jquery';

export default class VideoBanner {
	constructor() {

    let $videoBanner = $('.video-banner'),
    videoId = $videoBanner.data('videoId');

		PMO.playerReady = false;

    var tag = document.createElement('script'),
        firstScriptTag = document.getElementsByTagName('script')[0];

    if($('#youtube-api-call').length >0)
    tag.src = "https://www.youtube.com/iframe_api";

    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // function onYouTubeIframeAPIReady() {
    window.onYouTubeIframeAPIReady = function () {
      // console.log ('onYouTubeIframeAPIReady..');
      PMO.player = new YT.Player('ytplayer', {
        width: '100%',
        height: '530',
        videoId: videoId,
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange
        }
      });
    }

    function onPlayerReady(e) {
      // e.target.playVideo();
      // console.log ('player ready..');
      PMO.playerReady = true;
    }

    function onPlayerStateChange(e) {
      // console.log ('onPlayerStateChange:', e.data);
      PMO.playerState = e.data;
    }

    PMO.utils.isIE = (function(){
      // http://stackoverflow.com/questions/2400935/browser-detection-in-javascript
      var ua = navigator.userAgent, tem,
          M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

      if (/trident|msie/i.test(M[1])) {
        return true;
      }
      else {
        return false;
      }

    })();

    $('.ico-play').on ('click.ytplayer', function (e) {
    // console.log ('clicked on play button..');
      e.preventDefault();
      if (PMO.playerReady) {
        // console.log ('player was ready..');
        $('.video-banner').fadeIn();

        // if (isMobile.apple.device) {
        //   PMO.player.stopVideo();
        // }
        // else {
        PMO.player.playVideo();
        // }

        if (!PMO.utils.isIE) {
          $('.static-banner').addClass('fade-out');
          if ($(window).width() >= 768) $('.title-container').addClass('fade-out');
        }
        else {
          $('.static-banner').hide();
          $('.title-container').hide();
        }

        $('.hero-banner').on('mouseleave.ytplayer', function() {
          // console.log ('on mouseleave..', PMO.playerState);
          if (PMO.playerState !== 1) {
            $('.hero-banner').off('mouseleave.ytplayer');
            if (!PMO.utils.isIE) {
              $('.static-banner').removeClass('fade-out');
              $('.title-container').removeClass('fade-out');
            }
            else {
              $('.static-banner').show();
              $('.title-container').show();
            }
          }
        });
      }
    });
	}
}
