'use strict';

import $ from 'jquery';

export default class ShowMore {
	constructor() {
		var pluginName = 'showMore',
      defaults = { };

    /**
     * constructor..
     */
    function ShowMore (el, options) {

      var self = this;

      self.el = el;
      self.$el = $(el);
      self.showingMore = false,
      self.hideAfter = (!options) ? 5 : options.hideAfter;

      // init..
      if (self.$el.find('li').length > 6) {
        self.$el.find('li:gt('+ self.hideAfter +')').hide();
        self.$el.find('.lnk-show-more').show();
        self.$el.on('click', '.lnk-show-more', function (e) {
          e.preventDefault();
          self.showingMore = !self.showingMore;
          self.toggle();
        })
      }

      return this;
    };

    ShowMore.prototype.toggle = function () {
      var self = this;

      if (self.showingMore) {
        self.$el.find('li').show();
        self.$el.find('.js-txt-show-more').html('Show Less');
        self.$el.find('.js-txt-show-more-icon').removeClass('icon-plus-square').addClass('icon-minus-square');
      }
      else {
        self.$el.find('li:gt('+ self.hideAfter +')').hide();
        self.$el.find('.js-txt-show-more-icon').removeClass('icon-minus-square').addClass('icon-plus-square');
        self.$el.find('.js-txt-show-more').html('Show More');
      }
    }

    /**
     * registers plugin..
     */
    $.fn.extend({

      showmore: function(options) {
        return this.each(function() {
          $(this).data('plugin_' + pluginName, new ShowMore(this, options))
        });
      }

    });
	}
}
