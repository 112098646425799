'use strict';

import $ from 'jquery';
import HeaderHighlighter from '../header-highlighter/header-highlighter';

export default class TopicExplorer {
	constructor() {
		var pluginName = 'topicExplorer',
      defaults = { };

    /**
     * constructor..
     */
    function TopicExplorer (el, options) {

      var self = this,
          btnDown = null,
          lastScrollPos = 0;

      self.allTopics = $('span[data-topic]');
      self.selectedTopics = [];
      self.sectionId = 0;
      self.isFixed = false;
      self.marginOffset = 60;
      self.topicExplorerTop = $('.topic-explorer').offset().top + self.marginOffset;

      self.el = el;
      self.$el = $(el);
      self.$topicExplorer = $('.topic-explorer');

      // set events for the topics in the event table..
      $('.event-table').on('click.topicExplorer', '.event-table-topics', function (e) {
        e.preventDefault();
        var topicId = $(e.target).data('topicId');
        $('.topic-selector a[data-topic-id="' + topicId + '"]').trigger('click');
      });

      // set events for topic explorer buttons..
      self.$el.on('click.topicExplorer', '.topic-selector a', function (e) {
        e.preventDefault();

        var $this = $(this),
            id = $this.data('topicId');

        self.updateTopic(id);
      });

      // set events for select dropdown..
      self.$el.on('change.topicExplorer', 'select', function (e) {
        var id = parseInt($(this).val());
        if (id === 0) {
          // reset..
          self.resetTopicBtns();
        }
        else {
          self.updateTopic(id);
        }
      });

      // set events for the next / prev buttons..
      self.$el.on('click.topicExplorer', '.topic-selector-nav a', function (e) {
        e.preventDefault();
        var $target = $(e.target);

        if ($target.hasClass('ts-prev')) {
          self.sectionId--;
          if (self.sectionId < 0) self.sectionId = self.selectedTopics.length - 1;
          self.moveToSection(self.sectionId);
        }
        else if ($target.hasClass('ts-next')) {
         self.sectionId++;
         if (self.sectionId > (self.selectedTopics.length - 1)) self.sectionId = 0;
         self.moveToSection(self.sectionId);
        }
      });

      self.$el.on('click.topicExplorer', '.ts-clear', function (e) {
        e.preventDefault();
        self.$el.find('select').val(0);
        self.$el.find('select').trigger('change');
      });

      $(window).on('scroll.topicExplorer', function (e) {

        var windowTop = $(window).scrollTop();

        if (windowTop >= self.topicExplorerTop) {
          self.$topicExplorer.addClass('topic-explorer-fixed');
          self.$topicExplorer.next().css({ marginTop: self.$topicExplorer.height() + 50 });
        }
        else {
          self.$topicExplorer.removeClass('topic-explorer-fixed');
          self.$topicExplorer.next().css({ marginTop: 0 });

          // reset topicExplorer..
          // if (lastScrollPos > windowTop) self.resetTopicBtns();
        }

        lastScrollPos = windowTop;

      });

      // reset topicExplorer top position when resizing..
      $(window).on('resize.topicExplorer', function (e) {
        self.topicExplorerTop = $('.topic-explorer').offset().top + self.marginOffset;
      });

      return this;
    };

    TopicExplorer.prototype.getListOfSelectedTopics = function (id) {
      var self = this,
          list = self.allTopics.filter(function (index) {
            var topics = $(this).data('topic');
            return $.inArray(parseInt(id), topics) > -1;
          });
      return list;
    }

    TopicExplorer.prototype.reset = function (id) {
      var self = this;

      self.sectionId = 0;
      self.allTopics.removeClass('topic-highlighter');
    }

    TopicExplorer.prototype.resetTopicBtns = function (id) {
      var self = this;
      self.reset();
      $('.topic-selector-nav').hide();
      if (self.btnDown) {
        self.btnDown.removeClass('pill-active');
        self.btnDown = null;
      }
    }

    TopicExplorer.prototype.initNav = function (id) {
      var self = this;
      self.reset();
      self.updateProgress();
      $('.topic-selector-nav').show();
    }

    TopicExplorer.prototype.scrollTo = function (arg) {
      var top = !isNaN(arg) ? arg : $(arg).offset().top - ($('.topic-explorer').height() + 36);
      // console.log ('scrollTo top:', top, $('.topic-explorer').height());
      $('html, body').animate({ scrollTop: top }, 600);
    }

    TopicExplorer.prototype.moveToSection = function (id) {
      var self = this,
          $section = $(self.selectedTopics.get(id));

      self.allTopics.removeClass('topic-highlighter');

      self.updateProgress();

      self.scrollTo($section);
      $section.addClass('topic-highlighter');
    }

    TopicExplorer.prototype.updateTopic = function (id) {

      var self = this;

      if (self.btnDown) self.btnDown.removeClass('pill-active');

      self.btnDown = self.$el.find('ts' + id);
      self.btnDown.addClass('pill-active');

      self.selectedTopics = self.getListOfSelectedTopics (id);

      self.initNav();
      self.moveToSection(self.sectionId);
    }

    TopicExplorer.prototype.updateProgress = function () {
      var self = this;
      $('.topic-selector-progress p').html('<strong>' + (self.sectionId + 1) + '</strong> of <strong>' + self.selectedTopics.length + '</strong>');
    }

     TopicExplorer.prototype.scrollHandler = function () {
       console.log ('here..');
     }

    /**
     * registers plugin..
     */
    $.fn.extend({

      topicExplorer: function(options) {

          return this.each(function() {
              var $this = $(this),
                  t = $this.data('plugin_' + pluginName)
              $this.data('plugin_' + pluginName, new TopicExplorer(this, options))
          });

      }

    });

    // To be called first before initiatlised Topic Explorer
    if ($('.header-highlighter').length) {
      new HeaderHighlighter();
    } else {
      $('.topic-explorer').topicExplorer();
    }

	}
}
