'use strict';

import $ from 'jquery';

import SocialShare from '../../molecules/social-share/social-share';
import SiteTool from '../../molecules/site-tool/site-tool';

export default class PageHeader {
	constructor() {
		if ($('.share-component').length) {
      new SocialShare();
    }

    if ($('.page-options').length) {
      new SiteTool();
    }
	}
}
