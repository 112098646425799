'use strict';

import $ from 'jquery';
import imagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';

export default class TopicsList {
	constructor() {
		var $el = $('.topics-container');
    new imagesLoaded($el.get(0), function() {
      new Masonry($el.get(0), {
        columnWidth: '.grid-sizer',
        itemSelector: '.topic-item',
        gutter: 30
      });
    });
	}
}
