'use strict';

import $ from 'jquery';

export default class SiteTool {
	constructor() {
    var fontIndex = 0,
      H2SIZE = 24,
      SUMMARYSIZE = 20,
      H4SIZE = 18,
      BODYSIZE = 16,
      SMALLSIZE = 12,
      METASIZE = 14;

    PMO.utils.adjustFontSize = function (step) {

      if ((fontIndex + step) > 8) return;
      if ((fontIndex + step) < 0) return;

      fontIndex += step;

      var fontIncrement = (fontIndex * 2),
          bodySize = BODYSIZE + fontIncrement,
          summarySize = SUMMARYSIZE + fontIncrement,
          metaSize = METASIZE + fontIncrement,
          h2Size = H2SIZE + fontIncrement,
          h4Size = H4SIZE + fontIncrement,
          smallSize = SMALLSIZE + fontIncrement;

      $('.js-txtsize-up').removeClass('txtsize-disabled');
      $('.js-txtsize-down').removeClass('txtsize-disabled');

      if (bodySize >= 32) {
        $('.js-txtsize-up').addClass('txtsize-disabled');
      }
      else if (bodySize <= 16) {
        $('.js-txtsize-down').addClass('txtsize-disabled');
      }

      // adjust font size..
      $('body').css({ 'font-size': bodySize });
      $('h5').css({ 'font-size': bodySize });

      $('.summary').css({ 'font-size': summarySize });
      $('.delta').css({ 'font-size': summarySize });
      $('.gamma').css({ 'font-size': summarySize });
      $('.aside-title').css({ 'font-size': summarySize });

      $('.meta-data').css({ 'font-size': metaSize });
      $('.meta-table').css({ 'font-size': metaSize });
      $('.tag-label').css({ 'font-size': metaSize });
      $('.tags').css({ 'font-size': metaSize });
      $('.topic-listing').css({ 'font-size': metaSize });
      $('h6').css({ 'font-size': metaSize });
      $('.facet-category ul').css({ 'font-size': metaSize });

      $('h2').css({ 'font-size': h2Size });

      $('.item-count').css({ 'font-size': smallSize });
      $('.epsilon').css({ 'font-size': smallSize });

      $('h4').css({ 'font-size': h4Size });
    }

		$('.js-print').on('click', function (e) {
      e.preventDefault();
      window.print();
    });
    $('.js-txtsize-down').addClass('txtsize-disabled');
    $('.js-txtsize-up').on ('click', function (e) {
      e.preventDefault();
      PMO.utils.adjustFontSize(1);
    });

    $('.js-txtsize-down').on ('click', function (e) {
      e.preventDefault();
      PMO.utils.adjustFontSize(-1);
    });
	}
}
