'use strict';

import $ from 'jquery';
import 'qtip2';

export default class PmoChart {
	constructor() {
    PMO.utils.scrollTo = function (arg) {
      var top = !isNaN(arg) ? arg : $(arg).offset().top;
      $('html, body').animate({ scrollTop: top }, 600);
    }

		$('.pmo-chart-desktop [title!=""]').qtip({
      position: {
        my: 'bottom center',
        at: 'top center'
      },
      style: {
        classes: 'qtip-dark qtip-rounded'
      }
    });

    $('.pmo-circle').on('click', function (e) {
      var el = '#section-' + $(this).data('id');
      $(el).trigger('click');
      PMO.utils.scrollTo(el);
    });
	}
}
