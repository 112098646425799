'use strict';

import $ from 'jquery';

export default class Notification {
	constructor() {
      // hide notification on no msg || logged-in || non-exist notification
      if ($('#notification .notif-msg').html().length < 1 || ($('#notification .notif-msg') && $('#notification .notif-msg').html().split(' ').join('') == '' )) {
        $('#notification').hide();
        $('body').css({'transform':'initial'});
      } else {
        $('.notification-bar').show();
      }

	}
}
