'use strict';

import $ from 'jquery';

export default class ProfileTab {
  constructor() {
    var pluginName = 'profileTab',
    defaults = { };

    /**
    * constructor..
    */
    function ProfileTab (el, options) {

      var self = this;

      self.el = el;
      self.$el = $(el);
      self.$tabContent = $('.tab-content');
      self.$tabs = $('.profile-tab a');
      self.tabId = 0;

      // console.log ('ProfileTab..');

      // init..
      $(self.$tabContent.get(self.tabId)).show();
      let $btnDown = $(self.$tabs.get(self.tabId));
      $btnDown.addClass('active');

      self.$el.on('click', 'a', function (e) {
        e.preventDefault();

        // clean up..
        $btnDown.removeClass('active');
        $(self.$tabContent.get(self.tabId)).hide();

        // new id..
        $btnDown = $(e.target);
        self.tabId = $btnDown.index();

        // set..
        $btnDown.addClass('active');
        $(self.$tabContent.get(self.tabId)).show();
      })

      return this;
    };

    /**
    * registers plugin..
    */
    $.fn.extend({
      profileTab: function(options) {
        return this.each(function() {
          $(this).data('plugin_' + pluginName, new ProfileTab(this, options))
        });
      }
    });

    $('.profile-tab').profileTab();
  }
}
