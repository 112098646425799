'use strict';

import $ from 'jquery';

export default class FacetsContainer {
	constructor() {
		$('.btn-filter').on('click', function (e) {
      e.preventDefault();
      $('.facets').toggle();
    });

    $('.facet-category').showmore();
	}
}
