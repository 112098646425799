'use strict';

import $ from 'jquery';

export default class TopicFilters {
	constructor() {
		$('.js-topic-filters').on ('click', function (e) {
      e.preventDefault();
      var self = $(this),
          btnPillTags = $('.js-aside-tags');

      $('.topic-filters').toggle();

      if (self.parent().is('.js-group-filters-tags')) {

        self.toggleClass('pill-active');

        if(btnPillTags.hasClass('pill-active')){
          btnPillTags.removeClass('pill-active');
          $('.aside-tags').hide();
        }
      }
    });

    $(window).on ('orientationchange load resize', function () {
      var w = $(window).width();
      if (w > 769) {
        $('.topic-filters').show();
      } else {
        $('.topic-filters').hide();
      }
    })
	}
}
