'use strict';

export default class PhotoSlider {
	constructor() {
		$('#photothumb').find('ul.slides > li > *').removeAttr('width').removeAttr('height');
    $('#flexslider-1').find('ul.slides > li > *').removeAttr('width').removeAttr('height');

    let $slider = $('#flexslider-1');

    let slides_no = $slider.find('.slides li').length;

    $('#flex-custom-pager').find('.total').html(slides_no);

    $('#photothumb').flexslider({
      "easing": "swing",
      "direction": "horizontal",
      "reverse": false,
      "smoothHeight": false,
      "startAt": 0,
      "animationSpeed": 600,
      "initDelay": 0,
      "useCSS": true,
      "touch": true,
      "video": false,
      "keyboard": false,
      "multipleKeyboard": false,
      "mousewheel": 0,
      "controlsContainer": ".flex-control-nav-container",
      "sync": "",
      "asNavFor": "#flexslider-1",
      "itemWidth": 140,
      "itemMargin": 5,
      "minItems": 5,
      "maxItems": 5,
      "move": 5,
      "animation": "slide",
      "slideshow": false,
      "slideshowSpeed": "5000",
      "directionNav": true,
      "controlNav": false,
      "prevText": "Previous",
      "nextText": "Next",
      "pausePlay": false,
      "pauseText": "Pause",
      "playText": "Play",
      "randomize": false,
      "thumbCaptions": false,
      "thumbCaptionsBoth": false,
      "animationLoop": false,
      "pauseOnAction": true,
      "pauseOnHover": false,
      "manualControls": ""
    })

    $('#flexslider-1').flexslider({
      "easing": "swing",
      "direction": "horizontal",
      "reverse": false,
      "smoothHeight": false,
      "startAt": 0,
      "animationSpeed": 600,
      "initDelay": 0,
      "useCSS": true,
      "touch": true,
      "video": false,
      "keyboard": false,
      "multipleKeyboard": false,
      "mousewheel": 0,
      "controlsContainer": ".flex-control-nav-container",
      "sync": "#flexslider-2",
      "asNavFor": "",
      "itemWidth": 0,
      "itemMargin": 0,
      "minItems": 0,
      "maxItems": 0,
      "move": 0,
      "animation": "slide",
      "slideshow": true,
      "slideshowSpeed": "5000",
      "directionNav": false,
      "controlNav": false,
      "prevText": "Previous",
      "nextText": "Next",
      "pausePlay": true,
      "pauseText": "Pause",
      "playText": "Play",
      "randomize": false,
      "thumbCaptions": false,
      "thumbCaptionsBoth": false,
      "animationLoop": false,
      "pauseOnAction": false,
      "pauseOnHover": false,
      "manualControls": "",
      "after": function() {
        let curr_no = parseInt($('#flexslider-1').data('flexslider').currentSlide) + 1;

        $('#flex-custom-pager').find('.current').html(curr_no);
      }
    })
	}
}
