'use strict';

import $ from 'jquery';
import 'magnific-popup';

export default class PhotoGallery {
	constructor() {
		$('.photo-gallery').magnificPopup({
      delegate: '.photo-link',
      type: 'image',
      gallery: {
        enabled: true
      }
    });
	}
}
