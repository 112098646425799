'use strict';

import $ from 'jquery';

export default class HeaderHighlighter {
	constructor() {
		var topic = false;
    var currentIndex = 0;
    var headerHighlighter;
    var headerHighlighterbar;

    if ((typeof(headerHighlighterbar) !== "undefined") && (headerHighlighterbar != "")) {
      if ($('.div-line').length > 0) {
        headerHighlighter = $('.div-line').offset().top;
        var content = headerHighlighterbar;
        var newdiv = $("<div>");
        newdiv.html(content);
        $('.div-line').after(newdiv);
      }
    }
    if($('.header-highlighter').length){
        //headerHighlighter = $('.header-highlighter').offset().top;
      headerHighlighter = $('.div-line').offset().top;

      var content = $('.shortcode_highlighter').html();
      var newdiv = $("<div>");
      newdiv.html(content);

      $('.div-line').after(newdiv);
      $('.shortcode_highlighter').html('');
    }


    let $headerHighlighter = $('.header-highlighter'),
    $btn = $('.btn-pill', $headerHighlighter),
    $topicShowTrigger = $('.topic-show-trigger', $headerHighlighter);

    $btn.on('click', function(e) {
      e.preventDefault();
      choseTopic(this);
    });

    $topicShowTrigger.on('click', e => {
      e.preventDefault();
      onclicktopicShow();
    });

    $(window).on('resize', function () {
      topicshowTrigger('resize');
    });

    function onclicktopicShow(elem) {
      topicshowTrigger('click');
    }

    function topicshowTrigger(action) {
      if (action == 'click') {
        if ($('.topic-show-more').length > 0) {
          $('.header-highlighter .simple-list').animate({
            height: $('.header-highlighter .simple-list').get(0).scrollHeight
          }, 500);

          if ($('.topic-show-more').length > 0) {
            $('.topic-show-trigger').html('<span class="icon-chevron-up"></span>');
            $('.topic-show-trigger').removeClass("topic-show-more");
            $('.topic-show-trigger').addClass("topic-show-less");
          }
        } else {
          $('.header-highlighter .simple-list').css("overflow","hidden");
          $('.header-highlighter .simple-list').animate({height: "41px"}, 500);
          $('.topic-show-trigger').html('<span class="icon-chevron-down"></span>');
          $('.topic-show-trigger').addClass("topic-show-more");
          $('.topic-show-trigger').removeClass("topic-show-less");
        }
      } else {
          if ($('.header-highlighter .simple-list').prop("scrollHeight") > 42 ) {
            $('.header-highlighter .simple-list').css("overflow","hidden");
            $('.header-highlighter .simple-list').height(41);
            $('.topic-show-trigger').html('<span class="icon-chevron-down"></span>');
            $('.topic-show-trigger').addClass("topic-show-more");
            $('.topic-show-trigger').removeClass("topic-show-less");
        } else {
          $('.header-highlighter .simple-list').height($('.header-highlighter .simple-list').prop("scrollHeight"));
          $('.topic-show-trigger').removeClass("topic-show-more");
          $('.topic-show-trigger').removeClass("topic-show-less");
        }
      }
    }

    function choseTopic(elem){
        var topic_value = elem.getAttribute('data-topic');
        processShowHighlight(topic_value, 'topic');
    }

    function previousTopic(elem){
        var topic_value = elem.getAttribute('data-topic');
        processShowHighlight(topic_value, 'previous');
    }
    function nextTopic(elem){
        var topic_value = elem.getAttribute('data-topic');
        processShowHighlight(topic_value, 'next');
    }

    function processShowHighlight(topic_value, type){
      var groupTopic = [];
      var elements = document.querySelectorAll('span[data-topic]');

      if (topic == topic_value) {
          if (type === 'topic') {
              return;
          }
      }

      for (var i = 0, len = elements.length; i < len; i++) {
        //remove highlight
        $(elements[i]).removeClass("topic-highlighter");
        var val = elements[i].getAttribute('data-topic');
        if (val.indexOf(topic_value) > -1) {
          groupTopic.push(elements[i]);
        }
      }

      if (topic) {
        if (topic != topic_value) {
            currentIndex = 0;
            topic = topic_value;
        } else {
          if (type === 'next') {
            if (currentIndex < groupTopic.length - 1) {
              currentIndex++;
            } else {
              currentIndex = 0;
            }
          }
          if(type === 'previous'){
            if(currentIndex == 0){
              currentIndex = groupTopic.length - 1;
            } else {
              currentIndex--;
            }
          }
        }

        $('.topic-navigator').html('Showing <strong>' + (currentIndex + 1) + '</strong> of <strong>' + groupTopic.length + '</strong>  <a data-topic="'+ topic_value +'" class="ts-prev">previous</a> / <a data-topic="'+ topic_value +'" class="ts-next">next</a>');
      } else {
        topic = topic_value;
        $('.header-highlighter').append('<p class="topic-navigator">'+ 'Showing <strong>' + (currentIndex + 1) + '</strong> of <strong>' + groupTopic.length + '</strong>  <a data-topic="'+ topic_value +'" class="ts-prev">previous</a> / <a data-topic="'+ topic_value +'" class="ts-next">next</a></p>');
      }
      $('.topic-navigator .ts-prev').on('click', function() {
        previousTopic(this);
      });
      $('.topic-navigator .ts-next').on('click', function() {
        nextTopic(this);
      });

      let currentTop = groupTopic[currentIndex];
      //add class highlighter
      $(currentTop).addClass("topic-highlighter");

      var pageOffset = $(window).scrollTop();
      let animateScroll;

      if (pageOffset < 370) {
        animateScroll = 340;
      } else {
        animateScroll = 208;
      }
      if ($('.div-line').length > 0) {
        let divlineOffset = $('.div-line').offset().top;
        if (pageOffset < divlineOffset) {
          animateScroll = 340;
        }
      }
      $("html, body").animate({ scrollTop: $(currentTop).offset().top - animateScroll }, 600);
    }

    $(window).on('scroll', function() {
        var scrollTop = $(this).scrollTop();

        if($('.header-highlighter').length){
          //$('.header-highlighter').each(function() {
          $('.header-highlighter').each(function() {
            var topDistance = $(this).offset().top;
            if(scrollTop > headerHighlighter) {
              if(!$(this).hasClass('header-highlighter-scroll')) {
                $(this).addClass('header-highlighter-scroll');
              }
            } else {
              if($(this).hasClass('header-highlighter-scroll')) {
                $(this).removeClass('header-highlighter-scroll');
              }
            }
          });
        }
    });
	}
}
