'use strict';

import $ from 'jquery';

export default class SubscribeForm {
	constructor() {
    let $subscribe = $('.subscribe');
    let $pageIdField = $('.scfSingleLineTextBorder input', $subscribe);

    let pageId = $subscribe.data('pageId');

    $pageIdField.val(pageId);
	}
}
