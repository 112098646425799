'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class Multislide {
	constructor() {
		$('.slick-carousel-multislide').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
      ]
    });
	}
}
