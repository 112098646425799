'use strict';

import $ from 'jquery';

export default class Accordion {
	constructor() {
		var pluginName = 'accordion',
      defaults = { };

    /**
    * constructor..
    */
    function Accordion (el, options) {

      var self = this;

      self.el = el;
      self.$el = $(el);
      self.itemDown = null;

      self.$el.find('.accordion-header a').on('click', function (e) {
        e.preventDefault();
        //console.log ('haoeu');

        if (self.itemDown !== null) {
          // self.itemDown.parent().find('.accordion-content').hide();
          // self.itemDown.removeClass('icon-chevron-up').addClass('icon-chevron-down');
          if ($(self.itemDown).attr('id') !== $(this).attr('id')) {

            self.itemDown.parents('.accordion-item').find('.accordion-content').hide();
            self.itemDown.find('.icon-chevron-up').removeClass('icon-chevron-up').addClass('icon-chevron-down');

            self.itemDown = $(this);
            self.itemDown.find('.icon-chevron-down').removeClass('icon-chevron-down').addClass('icon-chevron-up');
            self.itemDown.parents('.accordion-item').find('.accordion-content').slideDown();
          }
          else if ($(self.itemDown).attr('id') === $(this).attr('id')) {
            self.itemDown.find('.icon-chevron-up').removeClass('icon-chevron-up').addClass('icon-chevron-down');
            self.itemDown.parents('.accordion-item').find('.accordion-content').slideUp();
            self.itemDown = null;
          }
        }
        else {
          //console.log('111');
          self.$el.find('.accordion-content').hide();
          self.itemDown = $(this);
          self.itemDown.find('.icon-chevron-down').removeClass('icon-chevron-down').addClass('icon-chevron-up');
          self.itemDown.parents('.accordion-item').find('.accordion-content').slideDown();
        }

      //   self.itemDown = $(this);

      //   // console.log($(self.itemDown).attr('id'), $(this).attr('id'));
      //   if ($(self.itemDown).attr('id') !== $(this).attr('id')) {

      //     self.itemDown.parent().find('.accordion-content').hide();
      //     self.itemDown.removeClass('icon-chevron-up').addClass('icon-chevron-down');

      //     self.itemDown = $(this);
      //     self.itemDown.removeClass('icon-chevron-down').addClass('icon-chevron-up');
      //     self.itemDown.parent().find('.accordion-content').slideDown();
      //   }
      //   else if ($(self.itemDown).attr('id') === $(this).attr('id')) {
      //     self.itemDown.removeClass('icon-chevron-up').addClass('icon-chevron-down');
      //     self.itemDown.parent().find('.accordion-content').slideUp();
      //     self.itemDown = null;
      //   }
      //   // else if (self.itemDown !== null) {
      //   //   self.itemDown.parent().find('.accordion-content').hide();
      //   //   self.itemDown.removeClass('icon-chevron-up').addClass('icon-chevron-down');
      //   // }
      });

      return this;
    };

    /**
     * registers plugin..
     */
    $.fn.extend({

      accordion: function(options) {
        return this.each(function() {
          $(this).data('plugin_' + pluginName, new Accordion(this, options))
        });
      }

    });

    $('.accordion').accordion();
	}
}
