'use strict';

import $ from 'jquery';

export default class HeroBanner {
	constructor() {
		$(window).on ('orientationchange load resize', function () {
      var w = $(window).width();

      var bannerHeight = $('.banner').height();

      $('#ytplayer').height(bannerHeight);
      if (w > 769) {
        if (bannerHeight && bannerHeight < 530) {
          $('.hero-banner').height(bannerHeight);
        }
        else {
          $('.hero-banner').height(530);
          $('#ytplayer').height(530);
        }
        $('.slider-indicators').css('top', '');
      } else {
        var bannerWidth = $('.banner').width();
          if (bannerWidth == w) {
            if (bannerHeight < 155) {
              $('.slider-indicators').css('top', bannerHeight-40);
            } else {
              $('.slider-indicators').css('top', bannerHeight-30);
            }
        } else {
            if (w < 479) {
              $('.slider-indicators').css('top', 105);
            } else {
              $('.slider-indicators').css('top', 205);
            }
        }

        if ($('.banner').length > 0) {
            $('.hero-banner').removeAttr('style');
        } else if ($('#ytplayer').length > 0) {
          var titleheight = $('.hero-banner .title-container').height();
          var videoheight = w/2;
          $('.hero-banner .title-container h1').css('margin-top', w/2 + 20);
          $('.hero-banner').height(titleheight);
          $('#ytplayer').height(w/2);
        }
      }
    });
	}
}
