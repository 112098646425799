// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import ObjectFitImages from 'object-fit-images';
import Bootstrap from '../_modules/bootstrap/bootstrap';
import Spamspan from '../_modules/spamspan/spamspan';
import Accordion from '../_modules/accordion/accordion';
import ShowMore from '../_modules/show-more/show-more';
import TopicExplorer from '../_modules/topic-explorer/topic-explorer';
import ProfileTab from '../_modules/profile-tab/profile-tab';

import PhotoSlider from '../_modules/molecules/photo-slider/photo-slider';
import PhotoGallery from '../_modules/molecules/photo-gallery/photo-gallery';
import PmoChart from '../_modules/molecules/pmo-chart/pmo-chart';
import TopicFilters from '../_modules/molecules/topic-filters/topic-filters';
import HeroBanner from '../_modules/molecules/hero-banner/hero-banner';
import SubscribeForm from '../_modules/molecules/subscribe-form/subscribe-form';

import Header from '../_modules/organisms/header/header';
import Notification from '../_modules/organisms/notification/notification';
import Multislide from '../_modules/organisms/multislide/multislide';
import PageHeader from '../_modules/organisms/page-header/page-header';
import VideoBanner from '../_modules/organisms/video-banner/video-banner';
import HomeCarousel from '../_modules/organisms/home-carousel/home-carousel';
import FacetsContainer from '../_modules/organisms/facets-container/facets-container';
import TopicsList from '../_modules/organisms/topics-list/topics-list';

$(() => {
  
  window.PMO = window.PMO || {};

  ObjectFitImages();

  new Bootstrap();

  new Accordion();
  new ShowMore();
  new Spamspan();

  if ($('.not-front').length) {
    //Newswroom Iframe Responsive
    $('iframe[src*="youtube.com"]').wrap('<div style="max-width:853px"><div class="videoWrapper"></div></div>');

  } // not-front

  // remove bootstrap from WFFM
  $('link[rel="stylesheet"][href*="bootstrap.min.css"]').remove();

  // hidden-print
  $('.btn-pmo').addClass('hidden-print');
  $('.breadcrumbs').addClass('hidden-print');
  $('#navbar-administration').addClass('hidden-print');
  $('.tabs--primary.nav.nav-tabs').addClass('hidden-print');

  setTimeout(function(){
    $(window).trigger('resize');
  }, 100);

  // Back to top button
  $('.btt').on('click', function (e) {
    e.preventDefault();
    $('body, html').animate({ scrollTop: 0 }, 'slow');
  });
  $(window).on('scroll', function (e) {
    if ($(window).scrollTop() > 200) {
      $('.btt').addClass('show');
    } else {
      $('.btt').removeClass('show');
    }
  });

  // From All.js
  window.requestAnimationFrame = window.requestAnimationFrame
    || window.mozRequestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.msRequestAnimationFrame
    || function(f){setTimeout(f, 1000/60)};

  if (!("ontouchstart" in document.documentElement)) {
    document.documentElement.className += " no-touch";
  }

  $('p em').addClass('caption');
  $('.row.qna em').addClass('rteCaption');
  $('.row.qna p em').addClass('rteCaptionP');
  $('.summary em').addClass('summaryCaption');
  PMO.utils = {};

  // search bar..
  $('nav.main').on ('focus', '.nav-search-bar', function (e) {
    $(e.target).addClass('search-bar-expand');
  }).on ('blur', '.nav-search-bar', function (e) {
    $(e.target).removeClass('search-bar-expand');
  });

  if ($('.hero-banner').length) {
    new HeroBanner();
  }


  if ($('.slick-carousel-home').length) {
    new HomeCarousel();
  }

  $('body').on ('click', '.notif-close', function (e) {
    e.preventDefault();
    $('body').css({ transform: 'translate(0,0)' });
  });

  // End from all.js

  new Header();

  if ($('.notification-bar').length) {
    new Notification();
  }

  if ($('.profile-tab').length) {
    new ProfileTab();
  }

  if ($('.topic-explorer').length) {
    new TopicExplorer()
  }

  if ($('.pmo-chart').length) {
    new PmoChart();
  }

  if ($('.topic-filters').length) {
    new TopicFilters();
  }

  if ($('.photo-slider').length) {
    new PhotoSlider();
  }

  if ($('.photo-gallery').length) {
    new PhotoGallery();
  }

  if ($('.share-component').length || $('.page-options').length) {
    new PageHeader();
  }

  if ($('.video-banner').length) {
    new VideoBanner();
  }

  if ($('.facets-container').length) {
    new FacetsContainer();
  }

  if ($('.slick-carousel-multislide').length) {
    new Multislide();
  }

  if ($('.topics-container').length) {
    new TopicsList();
  }

  if ($('.subscribe').length) {
    new SubscribeForm();
  }

  // From custom.js

  // var length = $('.view-homepage-quote.view-id-homepage_quote.view-display-id-block_1 .view-content .views-row').children().length;
  // if (length > 0) {
  // }
  // if (length === 0) {
  //   if(!$('body').hasClass('node-type-transcript') && !$('body').hasClass('node-type-media-release') && !$('body').hasClass('node-type-page') && !$('body').hasClass('node-type-video-page')){
  //     jQuery('body').addClass('notransform');
  //   }
  // }

// Social Share Component
  var socialSharer_transcript = ''+
  '<div class="row col-md-12">'+
  '<div class="dialogue-end"><img src="/Cwp/assets/pmocorp/images/icon-sg.svg">'+
  '<div class="endline"></div>'+
  '</div>'+
  '<div class="dialogue-share">'+
  '  <p>Share this</p>'+
  '    <section class="social-share">'+
  '      <a target="_blank" rel="noopener noreferrer" class="js-popup facebook"'+
  '        href="https://www.facebook.com/sharer/sharer.php?u={share.url}&title={share.title}">'+
  '        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32"><g><path d="M22 5.16c-.406-.054-1.806-.16-3.43-.16-3.4 0-5.733 1.825-5.733 5.17v2.882H9v3.913h3.837V27h4.604V16.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903H22V5.16z" fill-rule="evenodd"></path></g></svg>'+
  '        <span class="share-txt hidden-xs">Share</span>'+
  '      </a>'+
  '      <a target="_blank" rel="noopener noreferrer" class="js-popup twitter"'+
  '        href="https://twitter.com/intent/tweet?url={share.url}&text={share.title}">'+
  '        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32"><g><path d="M27.996 10.116c-.81.36-1.68.602-2.592.71a4.526 4.526 0 0 0 1.984-2.496 9.037 9.037 0 0 1-2.866 1.095 4.513 4.513 0 0 0-7.69 4.116 12.81 12.81 0 0 1-9.3-4.715 4.49 4.49 0 0 0-.612 2.27 4.51 4.51 0 0 0 2.008 3.755 4.495 4.495 0 0 1-2.044-.564v.057a4.515 4.515 0 0 0 3.62 4.425 4.52 4.52 0 0 1-2.04.077 4.517 4.517 0 0 0 4.217 3.134 9.055 9.055 0 0 1-5.604 1.93A9.18 9.18 0 0 1 6 23.85a12.773 12.773 0 0 0 6.918 2.027c8.3 0 12.84-6.876 12.84-12.84 0-.195-.005-.39-.014-.583a9.172 9.172 0 0 0 2.252-2.336" fill-rule="evenodd"></path></g></svg>'+
  '        <span class="share-txt hidden-xs">Tweet</span>'+
  '      </a>'+
  '      <a target="_blank" rel="noopener noreferrer" class="js-popup email"'+
  '        href="mailto:?&subject={share.title}&body={share.url}">'+
  '        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32"><g><g fill-rule="evenodd"></g><path d="M27 22.757c0 1.24-.988 2.243-2.19 2.243H7.19C5.98 25 5 23.994 5 22.757V13.67c0-.556.39-.773.855-.496l8.78 5.238c.782.467 1.95.467 2.73 0l8.78-5.238c.472-.28.855-.063.855.495v9.087z"></path><path d="M27 9.243C27 8.006 26.02 7 24.81 7H7.19C5.988 7 5 8.004 5 9.243v.465c0 .554.385 1.232.857 1.514l9.61 5.733c.267.16.8.16 1.067 0l9.61-5.733c.473-.283.856-.96.856-1.514v-.465z"></path></g></svg>'+
  '        <span class="share-txt hidden-xs">Email</span>'+
  '      </a>'+
  '      <a target="_blank" rel="noopener noreferrer" class="js-popup whatsapp hidden-md hidden-lg"'+
  '        href="whatsapp://send?text={share.title}+{share.url}">'+
  '        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32"><g><path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" fill-rule="evenodd"></path></g></svg>'+
  '        <span class="share-txt hidden-xs">Whatsapp</span>'+
  '      </a>'+
  '      <div class="share-total"><p>&nbsp;</p></div>'+
  '    </section> <!-- / .social share-->'+
  '</div>'+
  '</div>'+
  '';
  socialSharer_transcript = socialSharer_transcript
  .split('{share.url}').join(encodeURIComponent(location.href))
  .split('{share.title}').join(encodeURIComponent(document.title));

  $('.node-type-transcript .node-transcript .field-body').after(socialSharer_transcript);

  // End from custom.js
});

$(window).on('load', () => {
  $('html.js.load').removeClass('load').addClass('loaded');
});


